import React, { useCallback, useState } from 'react';

import { Layout, TextLayoutLeft, DocumentationContainer } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { getHeadings } from '../../components/Layout/TableOfContents/lib/helpers';
import { generateTitleAnchorId } from '../../lib/helpers';
import { Scroll } from '../../components/Scroll';

const BranchesPage = () => {
  const [headings, setHeadings] = useState([]);
  const layoutRef = useCallback(node => {
    if (!node) {
      setHeadings([]);

      return;
    }

    setHeadings(getHeadings(node));
  }, []);

  return (
    <Layout header={true} ref={layoutRef}>
      <SEO title="Branches" />
      <section className="ids-doc if block section" id="content">
        <DocumentationContainer>
          <TextLayoutLeft>
            <h1 className="ids-doc sg if heading largest">Branches</h1>
            <p className="ids-doc if text lead">What is IDS Branches..</p>
          </TextLayoutLeft>
          <span
            className="sg if heading large toc"
            style={{ marginTop: '4rem', marginBottom: '2rem', display: 'block' }}>
            Table of Contents
          </span>
          <nav className="ids-doc toc-inline" style={{ display: 'flex' }}>
            <ul className="ids-doc">
              {headings.map((heading, index) => {
                const _custom_path = '/branches';
                const _title = heading.textContent;
                const id = generateTitleAnchorId({ customPath: _custom_path, title: _title });

                return (
                  <li key={`page-section-nav-item-key-${index}`} className="ids-doc">
                    <Scroll type="id" offset={-104} element={id}>
                      <a href={`#${id}`} className="ids-doc">
                        {_title}
                      </a>
                    </Scroll>
                  </li>
                );
              })}
            </ul>
          </nav>
        </DocumentationContainer>
      </section>
      <section className="ids-doc if block section">
        <DocumentationContainer>
          <h2 className="ids-doc sg if heading large">Background</h2>
        </DocumentationContainer>
      </section>
      <section className="ids-doc if block section">
        <DocumentationContainer>
          <h2 className="ids-doc sg if heading large">Get started</h2>
        </DocumentationContainer>
      </section>
      <section className="ids-doc if block section">
        <DocumentationContainer>
          <h2 className="ids-doc sg if heading large">FAQ</h2>
          <div className="ids-doc if faqs">
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">What is the IDS Branching Strategy?</summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  To alleviate the maintenance for the IDS Team, and to make the consumers day to day life easier, we
                  have opted in for a branching strategy for our Design System. This means that the IDS Team will now
                  focus on creating and maintaining the _core_ components of the design system. Many components have
                  been created with special focus on the needs on specific teams. It is now up to the teams themselves
                  to maintain those components.
                </p>
                <p className="ids-doc if text body">
                  In short terms, each team that has specific components in the IDS will own and maintain their
                  components in a repository and scope managed by the IDS team. for relax that would be
                  <code className="if ids-doc language-">ids-relax-core</code>-repo and{' '}
                  <code className="if ids-doc language-">@ids-relax-core</code>-scope. For Open Pages, it will be
                  <code className="if ids-doc language-">ids-open-pages</code>/
                  <code className="if ids-doc language-">@ids-open-pages</code>, postfixed if necessary with{' '}
                  <code className="if ids-doc language-">-core</code> or{' '}
                  <code className="if ids-doc language-">-react</code>, depending on framework.
                </p>
                <p className="ids-doc if text body">
                  This will allow for better sharing and discoverability of the components themselves, between
                  designers, developers, teams and content managers. If a component in a branch is found to be of use
                  for several teams, we will put that component in the IDS core repository and scope.
                </p>
                <p className="ids-doc if text body">
                  With the introduction of the component specific CSS Variables, it will be easier to create your own
                  variants, and to customize components to fit your needs.
                </p>
                <p className="ids-doc if text body">
                  With the IDS Branching Strategy, the teams are no longer directly dependent on the release cycle of
                  the IDS Core, making the development life easier.
                </p>
                <p className="ids-doc if text body">
                  All of the component and design documentation for the respective branches/teams, is placed on our
                  documentation site.
                </p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">
                How to inherit/depend on IDS Core when we are currently not being able to keep up with verions?
              </summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  Only the specialized components are to be moved into their respective teams repo and scope, so the
                  dependency would be tied directly to IDS core in form of either CSS inheritance, Design Token usage
                  (preprocessor variables or CSS variables) or direct overrides. The specialized components can fix
                  their IDS core version as a dependency, if required.
                </p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">
                How to keep up/handle with breaking changes in IDS Core?
              </summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">See former point.</p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">
                Who is in control when breaking changes effecting the custom components should be done? Can we veto it?
              </summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  Breaking changes from IDS Core cannot be vetoed on a regular basis, but the IDS Team can be notified
                  of any concerns during our PO checkins and demos. For your specialized components, you are free to
                  follow your own release cycle.
                </p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">Could IDS Core be backwards compatible?</summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  Backwards compatibility is never achieved while using a Semver setup, as in{' '}
                  <code className="if ids-doc language-">major</code> versions.{' '}
                  <code className="if ids-doc language-">minor</code>
                  and <code className="if ids-doc language-">patch</code>-versions are backwards compatible. We still
                  have rules in place for backward compatible bug fixes for a set of versions.
                </p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">
                How to synchronize the dependency our custom component will have to the Core?
              </summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  That is up to you. You can either update deps on the fly, or fix a version to the IDS Core, as long as
                  it does not hinder updates of the VID.
                </p>
              </div>
            </details>
            <details className="ids-doc if faq">
              <summary className="ids-doc if question">
                The normal state is that we are one or two versions behind, how will that effect the new setup?
              </summary>
              <div className="ids-doc if answer">
                <p className="ids-doc if text body">
                  We suggest that you get up to speed of your deps to the latest version before starting the branching
                  strategy. YMMV, so we are always open to discuss your concerns!
                </p>
              </div>
            </details>
          </div>
        </DocumentationContainer>
      </section>
      <section className="ids-doc if block section">
        <DocumentationContainer>
          <h2 className="ids-doc sg if heading large">Resources</h2>
        </DocumentationContainer>
      </section>
    </Layout>
  );
};

export default BranchesPage;
